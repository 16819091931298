const Tokyos = [
    {
        title: "This is Tokyo 1", 
        pictureurl: "/images/64fe038c-0da0-413f-9fcd-dbb337ce3012.webp"
    },
    {
        title:"This is Tokyo 2 ",
        pictureurl :"/images/iit91mfsbd0wkubelcwg.jpg"
    },
    {
        title:"This is Tokyo 3 ",
        pictureurl :"/images/manuel-velasquez-ssfp9okORYs-unsplash.jpg"
    },
    {
        title:"This is Tokyo 4 ", 
        pictureurl :"/images/tokyoGettyImages-1031467664.webp"
    }
];

export default Tokyos;