import './AppSearch.css';

function AppSearch(props){
    const {value , OnValueChange} = props;
    return (
        <div className='AppSearch'>
        <input 
        className='SearchInput' 
        type="text"
        placeholder='Come and Find me'
        value = {value}
        onChange = {(event)=> {OnValueChange(event.target.value)}}
        />
    </div>
    );
}

export default AppSearch;